<template >
  <div id="page-wrapper">
    <div id="signin">

      <v-container>
        <v-card>
            <v-card-title class="justify-center"><h1 >FriHed</h1></v-card-title>
          <form v-if="!user.token" id="signinform" @submit.prevent="submit">
            <v-card-text>
                <p>FriHed is a simple, free, easy-to-use app for managing a freelance business. You need to have an account to use FriHed. Join up and experience freelancer-freedom!</p>
 
             <v-card-title class="justify-center">{{ signInText }}</v-card-title>
             <v-text-field
                v-model="email"
                type="email"
                name="email"
                id="email"
                placeholder="email"
              />
              <v-text-field
                v-model="password"
                type="password"
                name="password"
                id="password"
                placeholder="password"
              />
            </v-card-text>
            <v-card-actions>
              <v-btn type="submit">Submit</v-btn>
            </v-card-actions>
          </form>
        </v-card>

        <p class="alternate-text" v-if="!user.id">
          Don't have an account?
          <router-link to="/signup">Create an account</router-link>
        </p>
        <p class="alternate-text" v-if="!user.id">
          Forgot your password?
          <router-link to="/forgot">You can reset it.</router-link>
        </p>
        <div v-if="user.token">
          <v-card>
            <v-card-title>Logout</v-card-title>
            <v-card-text>
              <p>You are logged in as: {{ user.email }}</p>
              <p>Want to log out?</p>
            </v-card-text>
            <v-card-actions>
              <v-btn @click="signOut">Logout</v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "SignInForm",
  props: {
    msg: String,
  },
  watch: {
    user(newValue) {
      if (newValue.token) {
        this.$router.push({ path: "/" });
      }
    },
  },
  data() {
    return {
      email: null,
      password: null,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    error() {
      return this.$store.state.error;
    },
    response() {
      return this.$store.state.response;
    },
    signInText() {
      let text = "Sign In";
      if (this.user.token) {
        text = "Logout";
      }
      return text;
    },
  },
  methods: {
    submit() {
      let payload = {
        email: this.email,
        password: this.password,
      };
      this.$store.dispatch("signIn", payload);
    },
    signOut() {
      this.$store.dispatch("signOut");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#signin {
  /* margin-top: 150px; */
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}
#page-wrapper {
  padding-top: 100px;
  background-color: black;
  height: 100vh;
  background-image: url("../../assets/cornered-stairs.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.alternate-text {
  color: white;
  margin-top: 10px;
}
.alternate-text a {
  color: white;
}
</style>
